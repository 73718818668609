
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";
import { setCurrentPageActions } from "@/core/helpers/toolbar";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "users-form",
  emit: ["update-success"],
  components: {},
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      firstname: "", // Solo el campo de usuario
      lastname: "",
      phone: "",
      email: "",
      username: "",
    });
    const params = {};

    onMounted(() => {
      setCurrentPageBreadcrumbs("Mi Cuenta", []);
      setCurrentPageActions(false, false, []);
      LoadProfileInfo();
    });

    const LoadProfileInfo = () => {
      loading.value = true;
      store
        .dispatch(Actions.LOAD_DATA_PROFILE, params)
        .then((response) => {
          if (store.state.UsersModule.success) {
            formData.value.firstname = response.firstname;
            formData.value.lastname = response.lastname;
            formData.value.email = response.email;
            formData.value.phone = response.phone;
            formData.value.username = response.username;
          }
          loading.value = false;
        })
        .catch((response) => {
          displayErrors(store, "AuthModule");
          loading.value = false;
        });
    };

    const getUser = () => {
      return formData.value;
    };

    return {
      formData,
      formRef,
      loading,
      getUser,
    };
  },
});
